.fixed-plugin .button-container .github-btn {
    display: inline-block;
    font-size: 14px;
}


/*.fixed-plugin .button-container .github-btn .gh-btn,
.fixed-plugin .button-container .github-btn .gh-count{
    padding: 5px 7px 5px 7px;
}*/

.fixed-plugin .SocialMediaShareButton {
    display: inline-block;
    margin: 0 2px;
}

.fixed-plugin li>a,
.fixed-plugin .badge {
    transition: all 0.34s;
    -webkit-transition: all 0.34s;
    -moz-transition: all 0.34s;
}

.all-icons [class*="pe-"] {
    font-size: 40px;
}

.all-icons input {
    border: 0;
}

.all-icons .font-icon-detail {
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}

.all-icons .font-icon-detail input {
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}

#map {
    position: relative;
    width: 100%;
    height: 100%;
}

.map-container {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
}

.places-buttons .btn {
    margin-bottom: 30px;
}

.sidebar .nav>li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.sidebar .nav>li.active-pro a {
    background: rgba(255, 255, 255, 0.14);
    opacity: 1;
    color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
    text-align: center;
}

.fixed-plugin {
    position: absolute;
    top: 180px;
    right: 0;
    width: 64px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
    display: none;
}

.fixed-plugin .fa-cog {
    color: #ffffff;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
}

.fixed-plugin .dropdown-menu {
    right: 80px;
    left: auto;
    width: 290px;
    border-radius: 10px;
    padding: 0 10px;
}

.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
    right: 10px;
    margin-left: auto;
    left: auto;
}

.fixed-plugin .fa-circle-thin {
    color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
    color: #00bbff;
}

.footer-dropdown {
    top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
    top: 300px !important;
}

.fixed-plugin .dropdown-menu>.active>a,
.fixed-plugin .dropdown-menu>.active>a:hover,
.fixed-plugin .dropdown-menu>.active>a:focus {
    color: #777777;
    text-align: center;
}

.fixed-plugin img {
    border-radius: 0;
    width: 100%;
    max-height: 175px;
    margin: 0 auto;
}

.fixed-plugin .badge {
    border: 3px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
    border-color: #00bbff;
}

.fixed-plugin .badge-white {
    background-color: #ffffff;
}

.fixed-plugin .badge-black {
    background-color: #1dc7ea;
}

.fixed-plugin .badge-azure {
    background-color: #1dc7ea;
}

.fixed-plugin .badge-green {
    background-color: #87cb16;
}

.fixed-plugin .badge-orange {
    background-color: #ffa534;
}

.fixed-plugin .badge-purple {
    background-color: #9368e9;
}

.fixed-plugin .badge-red {
    background-color: #fb404b;
}

.fixed-plugin h5 {
    font-size: 14px;
    margin: 10px;
}

.fixed-plugin .dropdown-menu li {
    display: block;
    padding: 5px 2px;
    width: 25%;
    float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
    width: 100%;
    height: 50px;
    min-height: inherit;
    text-align: center;
}

.fixed-plugin .pro-title {
    margin: 10px 0 5px 0;
    text-align: center;
}

.fixed-plugin #sharrreTitle {
    text-align: center;
    padding: 10px 0;
    height: 50px;
}

.fixed-plugin li.header-title {
    height: 30px;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
    float: left;
    display: inline-block;
    margin-bottom: 0;
    font-size: 1em;
}

.fixed-plugin .adjustments-line .switch {
    float: right;
}

.fixed-plugin .dropdown-menu>li.adjustments-line>a {
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
    border: 3px solid #fff;
    padding-left: 0;
    padding-right: 0;
    opacity: 1;
    cursor: pointer;
    max-height: 86px;
    overflow: hidden;
    padding: 0;
}

.fixed-plugin .dropdown-menu>li>a.switch-trigger:hover,
.fixed-plugin .dropdown-menu>li>a.switch-trigger:focus {
    background-color: transparent;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder,
.fixed-plugin .dropdown-menu>li:focus>a.img-holder {
    border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu>.active>a.img-holder,
.fixed-plugin .dropdown-menu>.active>a.img-holder {
    border-color: #00bbff;
    background-color: #ffffff;
}

.fixed-plugin .dropdown-menu>li>a img {
    margin-top: auto;
}

.fixed-plugin .btn-social {
    width: 50%;
    display: block;
    width: 48%;
    float: left;
    font-weight: 600;
}

.fixed-plugin .btn-social i {
    margin-right: 5px;
}

.fixed-plugin .btn-social:first-child {
    margin-right: 2%;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    opacity: 1;
}

@media (min-width: 992px) {
    .fixed-plugin .dropdown .dropdown-menu {
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 27px;
        opacity: 0;
        transform-origin: 0 0;
    }
    .fixed-plugin .dropdown.open .dropdown-menu {
        opacity: 1;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        transform-origin: 0 0;
    }
    .fixed-plugin .dropdown-menu:before,
    .fixed-plugin .dropdown-menu:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        width: 16px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
    }
    .fixed-plugin .dropdown-menu:before {
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid rgba(0, 0, 0, 0.2);
        border-top: 16px solid rgba(0, 0, 0, 0);
        right: -16px;
    }
    .fixed-plugin .dropdown-menu:after {
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid #ffffff;
        border-top: 16px solid rgba(0, 0, 0, 0);
        right: -15px;
    }
    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }
    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
    .fixed-plugin {
        top: 300px;
    }
}

@media (max-width: 991px) {
    .fixed-plugin .dropdown-menu {
        right: 60px;
        width: 220px;
    }
    .fixed-plugin .dropdown-menu li {
        width: 50%;
    }
    .fixed-plugin li.adjustments-line,
    .fixed-plugin li.header-title,
    .fixed-plugin li.button-container {
        width: 100%;
        height: 55px;
        min-height: inherit;
    }
    .fixed-plugin .adjustments-line .switch {
        float: right;
        margin: 0 0px;
    }
    .fixed-plugin li.header-title {
        height: 40px;
    }
    .fixed-plugin .dropdown .dropdown-menu {
        top: -170px;
    }
}

.btn-social {
    opacity: 0.85;
    padding: 8px 9px;
}

.btn-social .fa {
    font-size: 18px;
    vertical-align: middle;
    display: inline-block;
}

.btn-social.btn-round {
    padding: 9px 10px;
}

.btn-social.btn-simple {
    padding: 9px 5px;
    font-size: 16px;
}

.btn-social.btn-simple .fa {
    font-size: 20px;
    position: relative;
    top: -2px;
    width: 24px;
}

.btn-facebook {
    border-color: #3b5998;
    color: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open>.btn-facebook.dropdown-toggle {
    background-color: transparent;
    color: #3b5998;
    border-color: #3b5998;
    opacity: 1;
}

.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
    background-color: transparent;
    border-color: #3b5998;
}

.btn-facebook.btn-fill {
    color: #ffffff;
    background-color: #3b5998;
    opacity: 0.9;
}

.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open>.btn-facebook.btn-fill.dropdown-toggle {
    background-color: #3b5998;
    color: #ffffff;
    opacity: 1;
}

.btn-twitter {
    border-color: #55acee;
    color: #55acee;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open>.btn-twitter.dropdown-toggle {
    background-color: transparent;
    color: #55acee;
    border-color: #55acee;
    opacity: 1;
}

.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
    background-color: transparent;
    border-color: #55acee;
}

.btn-twitter.btn-fill {
    color: #ffffff;
    background-color: #55acee;
    opacity: 0.9;
}

.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open>.btn-twitter.btn-fill.dropdown-toggle {
    background-color: #55acee;
    color: #ffffff;
    opacity: 1;
}

@media (min-width: 992px) {
    .typo-line {
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }
    .typo-line .category {
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

.all-icons [class*="pe-"] {
    font-size: 40px;
}

.all-icons input {
    border: 0;
}

.all-icons .font-icon-detail {
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}

.all-icons .font-icon-detail input {
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}

.places-buttons .btn {
    margin-bottom: 30px;
}

.sidebar .nav>li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.sidebar .nav>li.active-pro a {
    background: rgba(255, 255, 255, 0.14);
    opacity: 1;
    color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
    text-align: center;
}

.react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4d4d4d;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #5d5d5d;
}

.react-toggle--checked .react-toggle-track {
    background-color: #1fc6ea;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #1fc6ea;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
    outline: 0;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #1fc6ea;
}

.github-btn {
    font: bold 11px/14px "Helvetica Neue", Helvetica, Arial, sans-serif;
    height: 20px;
    overflow: hidden;
}

.gh-btn,
.gh-count,
.gh-ico {
    float: left;
}

.gh-btn,
.gh-count {
    padding: 2px 5px 2px 4px;
    color: #333;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 3px;
}

.gh-btn {
    background-color: #eee;
    background-image: -webkit-gradient( linear, left top, left bottom, color-stop(0, #fcfcfc), color-stop(100%, #eee));
    background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
    background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
    background-repeat: no-repeat;
    border: 1px solid #d5d5d5;
}

.gh-btn:hover,
.gh-btn:focus {
    text-decoration: none;
    background-color: #ddd;
    background-image: -webkit-gradient( linear, left top, left bottom, color-stop(0, #eee), color-stop(100%, #ddd));
    background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
    background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
    border-color: #ccc;
}

.gh-btn:active {
    background-image: none;
    background-color: #dcdcdc;
    border-color: #b5b5b5;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

.gh-ico {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.gh-count {
    position: relative;
    display: none;
    /* hidden to start */
    margin-left: 4px;
    background-color: #fafafa;
    border: 1px solid #d4d4d4;
}

.gh-count:hover,
.gh-count:focus {
    color: #4183c4;
}

.gh-count:before,
.gh-count:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.gh-count:before {
    top: 50%;
    left: -3px;
    margin-top: -4px;
    border-width: 4px 4px 4px 0;
    border-right-color: #fafafa;
}

.gh-count:after {
    top: 50%;
    left: -4px;
    z-index: -1;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #d4d4d4;
}

.github-btn-large {
    height: 30px;
}

.github-btn-large .gh-btn,
.github-btn-large .gh-count {
    padding: 3px 10px 3px 8px;
    font-size: 16px;
    line-height: 22px;
    border-radius: 4px;
}

.github-btn-large .gh-ico {
    width: 20px;
    height: 20px;
}

.github-btn-large .gh-count {
    margin-left: 6px;
}

.github-btn-large .gh-count:before {
    left: -5px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
}

.github-btn-large .gh-count:after {
    left: -6px;
    margin-top: -7px;
    border-width: 7px 7px 7px 0;
}

.typography-line span {
    bottom: 10px;
    color: #9a9a9a;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    left: 5px;
    position: absolute;
    width: 260px;
    text-transform: none;
}

.typography-line {
    padding-left: 15%;
    margin-bottom: 35px;
    position: relative;
    display: block;
    width: 100%;
}

.btn-primary{
    color: black;
    background-color: rgb(243, 146, 0);
    border-color: rgb(243, 146, 0);
}

.btn-secondary{
    color: #ffffff;
    background-color: rgb(15, 23, 39);
    border-color: rgb(15, 23, 39);
}

.btn-primary:hover,
.btn-secondary:hover{
    color: rgb(15, 23, 39);
    background-color: transparent;
    border-color: rgb(15, 23, 39);
}

.form-check-input{
    margin-left: 0 !important;
}

.crud-icons{
    margin-right: 10px;
    border: 1px solid;
    padding: 5px 7px;
    color: rgb(15, 23, 39);
    background-color: rgb(243, 146, 0);
}

.crud-icons:hover{
    border-radius: 50%;
    color: rgb(243, 146, 0);
    background-color: rgb(15, 23, 39);
    border-color: transparent;
}

.options-data{
    display: flex;
    align-items: center;
}

.page-link-active > a{
    background-color: rgb(15, 23, 39) !important;
    color: #ffffff !important;
}
.page-link-disabled > a,
.page-link-disabled:hover > a
.page-link-disabled:hover > a:focus
{
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.preload{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: #ffffff;
}

.preloader-spinner{
    background-image: url("../img/preloader.gif");
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.disabled, .disabled:hover{
    box-shadow: none !important;
    cursor: not-allowed !important;
    opacity: 0.5 !important;
    background-color: #e9ecef !important;
    border-color: #dee2e6 !important;
    color: rgb(15, 23, 39) !important;
    pointer-events: none !important;
}

.preview {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 300px;
}

.close-picture-container {
    position: relative;
    width: 100%;
    height:100%;
    max-width: 300px;
}

.close-picture-container .close {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
    padding: 10px;
    background-color: #FF4A55;
    border: 0;
    color: #ffff;
    opacity: 1;
}

.user-log {
    display:flex;
    flex-direction:column;
}

#removeUserImg {
    padding: 0px !important;
}

.preview img {
    width: 100%;
}

.picture__input {
    display: none;
}

.picture {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
    height: 100%;
    border-radius: .375rem;
    aspect-ratio: 16/9;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    border: 2px dashed currentcolor;
    cursor: pointer;
    font-family: sans-serif;
    transition: color 300ms ease-in-out, background 300ms ease-in-out;
    outline: none;
    overflow: hidden;
    /*position: absolute;*/
    bottom: 0;
    left: 0;
    height: 100%;
    top: 0;
}

.picture__image{
    width: 100%;
}

.list-img{
    object-fit: contain;
}

.calendar-icon{
    width: 40px;
}
.datapickerContainer input{
    width: 100%;
}
.datapickerContainer{
    width: calc(100% - 45px);
    margin-left: 3px;
}

.w-40{
    width: 40px !important;
}

.w100-40{
    width: calc(100% - 45px);
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.sidebar .sidebar-wrapper .nav li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

div.logo{
    /*border-bottom: 1px solid rgba(255, 255, 255, 0.2);*/
    border-bottom: 1px solid;
}

.p-12-10{
    padding: 12px 10px;
}

.mobile-menu{
    background-color: rgb(15, 23, 39) !important;
    border-color: rgb(15, 23, 39) !important;
    color: rgb(243, 146, 0) !important;
    font-size: inherit !important;
}
.main-panel > div.content{
    overflow-y: auto !important;
    height: calc(100vh - 140px) !important;
    min-height: calc(100vh - 140px) !important;
}

.main-panel{
    overflow: hidden !important;
}

.max-width-300{
    max-width: 300px !important;
}

.zoom-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px;
    width: 100%;
}

.zoom-container > i:before{
    color: #9A9A9A !important;
}

.zoom-container > *{
    margin: 0 5px!important;
}

.zoom-container input[type=range]{
    width: 80%;
}

/*FullCalendar*/
/*.fc-direction-ltr .fc-timegrid-col-events{*/
    /*margin: 0 !important;*/
/*}*/

.fc-timegrid-slots > table td{
    /*padding: 20px;*/
}

.fc-timegrid-col-events{
    right: 35px !important;
}

.modal.show .modal-dialog {
    transform: none !important;
    min-width: 800px !important;
}

.card-body{
    /*max-height: 70vh;*/
    /*overflow-y: auto;*/
}

.fc-button-primary{
    color: rgb(15, 23, 39) !important;
    background-color: rgb(243, 146, 0) !important;
    background-color: rgb(243, 146, 0) !important;
    border-color: rgb(15, 23, 39) !important;
}

.fc-button-primary:focus{
    box-shadow: none !important;
}

.fc-button-primary:hover{
    color: rgb(15, 23, 39) !important;
    background-color: transparent !important;
    border-color: rgb(15, 23, 39) !important;
}

.fc-button-active{
    color: rgb(243, 146, 0) !important;
    background-color: rgb(15, 23, 39) !important;
    border-color: rgb(15, 23, 39) !important;
}

.fc-col-header-cell-cushion{
    color: rgb(15, 23, 39) !important;
}

.fc-daygrid-day-number, .fc-daygrid-event, .fc-list-day-text, .fc-list-day-side-text {
    color: rgb(15, 23, 39) !important;
}

.fc-more-link {
    color: #bf9000 !important;
}

.delete-event-calendar{
    float: right;
}
/*FullCalendar*/

.yellow-primary-color{
    color: rgb(243, 146, 0) !important;
}

.spinner-container-login{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-item.dropdown.show > .nav-link,
.dropdown-item:hover,
.nav-item > .nav-link:hover{
    color: rgb(243, 146, 0) !important;
    background: rgb(15, 23, 39) !important;
    transition: all 150ms ease-in;
}

.nav-item.dropdown.show > .nav-link,
.nav-item > .nav-link:hover {
    border-radius: 4px;
}

.nav-item > .nav-link{
    margin: 0 5px !important;
}

p.copyright > a{
    color: rgb(15, 23, 39) !important;
}

/*Paginate*/
.dataTables-info, .dataTables-showing-per-page{
    display: inline-flex;
    justify-content: start;
    align-items: center;
}

ul.pagination{
    margin: 0 !important;
}
.mr-5{
    margin-right: 5px !important;
}
.mb-25{
    margin-bottom: 25px !important;
}

.navbar .btn {
    margin: 0;
}

.paginate-flex-mobile{
    text-align: center;
    display: inline-flex;
    align-items: center;
}
.paginate-flex-mobile > .dataTables-info{
    float: left;
}

.paginate-flex-mobile:last-child{
    justify-content: right;
}

.paginate-flex-mobile > ul.pagination{
    float: right;
}

.page-link-active > a{
    background-color: rgb(15, 23, 39) !important;
    color: #ffffff !important;
}
.page-link-disabled > a,
.page-link-disabled:hover > a
.page-link-disabled:hover > a:focus
{
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

nav.navbar, div.logo{
    height: 80px;
}

select{
    min-width: 100px;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 15px top 50% !important;
    background-size: 0.65rem auto !important;
}

.filterContainer{
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    flex-grow: 1;
    /*width: calc(100% - 100px);*/
}

.filterContainer > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
}

.repeticion_time > *{
    max-width: 49%;
}

.container-check-input-same-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-check-input-same-row > div:first-child{
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-check-input-same-row > div:last-child{
    width: calc(100% - 45px);
}

.tbar{
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.btn-add{
    height: 46px;
}

.add-button-container{
    width: 90px;
}

.collapsePanel{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    padding-top: 10px;
}

.mr-10{
    margin-right: 10px;
}

.button-filters{
    margin-right: 10px;
}

.general-filters-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

#title-section-id{
    text-transform: uppercase;
}

.calendar-icon > span.input-group-text{
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.form-control{
    border: 1px solid #E3E3E3 !important;
}

.input-container input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input[name="ExpirationDateEnabled"]{
    margin: 0 0 1rem 0 !important;
}

.rrule-container .btn-primary{
    color: #ffffff;
    background-color: rgb(15, 23, 39);
    border-color: rgb(15, 23, 39);
}

.rrule-container div{
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-align: left !important;
}

/*.rrule-container */

@media (max-width: 768px) {
    .general-filters-container{
        display: block;
    }
    .button-filters{
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
    #title-section-id{
        position: absolute;
        top: 0;
        left: 5px;
    }
    nav.navbar{
        margin-top: 60px;
        position: initial;
    }
    nav.navbar, div.logo{
        height: auto;
    }
    .paginate-flex-mobile, .paginate-flex-mobile > .dataTables-info, .paginate-flex-mobile > ul.pagination{
        float: none !important;
        justify-content: center !important;
    }

    .navbar-collapse .nav-link{
        color: rgb(243, 146, 0) !important;
    }

    .navbar-collapse{
        z-index: 9999;
        background: rgb(15, 23, 39) !important;
        margin-top: 5px;
    }
}
/*Paginate*/

fieldset legend{
    text-align: center;
}

.audio_file_options{
    width: 30%;
    margin-left: -60px;
}

/* Analytics */
.apexcharts-legend {
    max-height: 100px !important;
}